import React from 'react';

import './card-header.styles.scss';

const CardHeader = () => (

  <div className="card-header">
    <div className="menu-wrapper">
      <form className="menu">
          <input type="radio" id="social" name="type" className="option" defaultChecked="checked"/>
          <label htmlFor="social" className="option">Social</label>

          <input type="radio" id="bio" name="type" className="option" />
          <label htmlFor="bio" className="option" >Bio</label>

          <input  type="radio" id="links" name="type" className="option"/>
          <label htmlFor="links" className="option" >Links</label>
      </form>
    </div>
  </div>

)

export default CardHeader;
import React from "react";

import "./profile-header.styles.scss";

const ProfileHeader = ({
  displayName,
  userName,
  link,
  avatar,
  description,
  country,
}) => (
  <div className="profile-header">
    <div className="picture-container">
      <img src={avatar} alt="Avatar" />
    </div>
    <div className="profile-info">
      <div className="display-name">{displayName}</div>
      {/* 
        <a className="user-name" href={link}>@{userName}</a>
        <div className="description">{description}</div>
        <div className="country">{country}</div>
        */}
    </div>
  </div>
);

export default ProfileHeader;

import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ProfilePage from "./pages/profile/profile.component";
import SignUpPage from "./pages/sign-up/sign-up.component";
import SignInPage from "./pages/sign-in/sign-in.component";
import LandingPage from "./pages/landing/landing.component";
import "./App.css";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null,
    };
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/signin" component={SignInPage} />
            <Route exact path="/signup" component={SignUpPage} />
            <Route exact path="/:username" component={ProfilePage} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

import React from 'react';

import './landing.styles.scss';

class LandingPage extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className='header'>
            <div className='logo-container'>
              <img src='./img/logo.svg' alt='logo' srcset='' />
              <h4 className='logo'>Fiind.me</h4>
            </div>
            <nav>
              <ul className='nav-links'>
                <li>
                  <a href='/#' className='nav-link'>
                    Features
                  </a>
                </li>
                <li>
                  <a href='/#' className='nav-link'>
                    Pricing
                  </a>
                </li>
                <li>
                  <a href='/#' className='nav-link'>
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
            <div className='actions'>
              <button className='flat-secondary-button'>Sign in</button>
              <button className='flat-primary-button'>Sign up</button>
            </div>
            <div className='hamburger' id='hamburger'>
              <div className='line'></div>
              <div className='line'></div>
              <div className='line'></div>
              <div className='cross'>&#10006;</div>
            </div>
          </div>
          <section className='presentation'>
            <div className='introduction'>
              <div className='intro-text'>
                <h1>Connect audiences</h1>
                <p>Internet is a busy place, bring some order</p>
              </div>
              <div className='cta'>
                <button className='btn-shadow cta-start'>Get started</button>
              </div>
            </div>
            <div className='cover'>
              <img
                src='img/iphone.png'
                alt='phone-demo'
                className='phone-demo'
                id='phone-demo'
                srcset=''
              />
            </div>
          </section>
        </header>

        <article className='feature'>
          <div className='illustration reverse screenshot cover animate-left'>
            <img
              src='./img/iphone-x-fiind-one.png'
              className='phone-demo-'
              alt=''
              srcset=''
            />
          </div>
          <div className='content'>
            <div className='title'>Stay in touch with your audience</div>
            <div className='description'>
              With only one link, you will be able to redirect your audience to
              all the platforms you are part of.
            </div>
            <div className='steps'>
              <div className='step'>
                <i className='fas fa-at fa-2x'></i>
                Pick a username
              </div>
              <div className='step'>
                <i className='fas fa-link fa-2x'></i>
                Add all your links
              </div>
              <div className='step'>
                <i className='fas fa-share fa-2x'></i>
                Share your profile!
              </div>
            </div>
          </div>
        </article>

        <div className='slider possible-links'>
          <div className='slide-track'>
            <i className='slide fab fa-instagram fa-3x'></i>
            <i className='slide fab fa-facebook fa-3x'></i>
            <i className='slide fab fa-twitter fa-3x'></i>
            <i className='slide fab fa-youtube fa-3x'></i>
            <i className='slide fab fa-twitch fa-3x'></i>
            <i className='slide fab fa-snapchat fa-3x'></i>
            <i className='slide fab fa-linkedin fa-3x'></i>
            <i className='slide fab fa-reddit fa-3x'></i>
            <i className='slide fab fa-tiktok fa-3x'></i>
            <i className='slide fas fa-envelope fa-3x'></i>
            <i className='slide fas fa-link fa-3x'></i>
            <i className='slide fab fa-soundcloud fa-3x'></i>
            <i className='slide fab fa-medium fa-3x'></i>
            <i className='slide fab fa-wordpress fa-3x'></i>
          </div>
        </div>

        <article className='feature reverse'>
          <div className='content'>
            <div className='title'>One username to rule them all</div>
            <div className='description'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum
              tempore consectetur aut velit deserunt corporis.
            </div>
            <div className='input-username-wrapper'>
              <div className='input-username btn-shadow'>
                <span>fiind.me/</span>
                <input className='input' placeholder='username' type='text' />
                <i className='fas fa-chevron-circle-right fa-2x'></i>
              </div>
            </div>
          </div>
          <div className='illustration animate-right'>
            <img src='./img/undraw_mention_6k5d.svg' alt='' srcset='' />
          </div>
        </article>
        <article className='pricing-wrapper'>
          <div className='card'>
            <div className='card-header'>
              <div className='title'>Free</div>
              <div className='price'>0$</div>
              <div className='action'>
                <button>Now</button>
              </div>
            </div>
            <div className='card-content'>
              <div>bla</div>
              <div>om</div>
              <div>bla</div>
              <div>om</div>
              <div>bla</div>
              <div>om</div>
            </div>
          </div>
          <div className='card'>
            <div className='card-header'>
              <div className='title'>Free</div>
              <div className='price'>0$</div>
              <div className='action'>
                <button>Now</button>
              </div>
            </div>
            <div className='card-content'>
              <div>bla</div>
              <div>om</div>
              <div>bla</div>
              <div>om</div>
              <div>bla</div>
              <div>om</div>
            </div>
          </div>
        </article>
        <footer>
          <div className='wrapper'>
            <div className='row'></div>
            <div className='separator-line'></div>
            <div className='about'>
              <p>Fiind.me</p>
              <div className='social'>
                <i className='fab fa-instagram fa-lg'></i>
                <i className='fab fa-facebook fa-lg'></i>
                <i className='fab fa-twitter fa-lg'></i>
              </div>
            </div>

            <script src='script.js'></script>
            <script
              src='https://kit.fontawesome.com/b698ce631c.js'
              crossorigin='anonymous'
            ></script>
          </div>
        </footer>
      </div>
    );
  }
}

export default LandingPage;

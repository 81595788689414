import axios from "axios";

export default async function authService(email, password) {
  axios
    .post("http://localhost:5000/api/v1/auth/login/", {
      email,
      password,
    })
    .then((res) => console.log(res.data))
    .catch((err) => console.log(err));
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare, faInstagramSquare,faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import './link-item.styles.scss'

function getBrandIcon(type){
  switch (type) {
    case 'fb':
      return <FontAwesomeIcon icon={faFacebookSquare} size="3x"/>
    case 'tw':
      return <FontAwesomeIcon icon={faTwitterSquare} size="3x"/>
    case 'ig':
      return <FontAwesomeIcon icon={faInstagramSquare} size="3x"/>
    default:
      break;
  }
}


const LinkItem = ({title,user,icon,url,type}) => (
  
  <div className='link-card-wrapper'>
    <div className='link-card'>
      <div className='image-container'>
        {getBrandIcon(type)}
      </div>
      <div className='details'>
        <a href={url}>{user}</a>
      </div>
    </div>
    {/*<div className="separator-line"></div>*/}
  </div>

)

export default LinkItem;
import React from 'react';

import ProfileHeader from  '../../components/profile-header/profile-header.component';

import './profile.styles.scss';
import CardHeader from '../../components/card-header/card-header.component';
import CardFooter from '../../components/card-footer/car-footer.component';
import LinkList from '../../components/link-list/link-list.component';

class ProfilePage extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      profile: {
        displayName: 'Traian Alexandru Mirci',
        userName: 'traianmirci',
        link: 'http://fiind.me/traian',
        avatar: 'https://avatars1.githubusercontent.com/u/25345045?s=460&u=ce388cd9c959128460483c392d51ffa097cd917d&v=4',
        description: 'Web Developer',
        country: 'Spain'
      },
      links: [
        {
          title: 'Instagram',
          icon: './icons/Instagram_Rounded_Square_Color.svg',
          user: 'Instagram',
          url: 'http://instagram.com/traianmirci',
          type: 'ig'
        },
        {
          title: 'Twitter',
          icon: './icons/Twitter_Rounded_Square_Color.svg',
          user: 'Twitter',
          url: 'http://twitter.com/traianmirci',
          type: 'tw'
        },
        {
          title: 'Facebook',
          icon: 'https://images.vexels.com/media/users/3/140171/isolated/lists/8d077b26a96a8a649a285dcd99bb97dc-icono-de-goma-de-facebook.png',
          user: 'Facebook',
          url: 'http://facebook.com/traianmirci',
          type: 'fb'
        },
      ]
    };
  }
  
  render(){
    const {displayName,userName, link, avatar, description, country} = this.state.profile;
    const {links} = this.state;

    return(
      <div className="main">
        <div className="bg-image">
          <img alt="Profile" src="https://avatars1.githubusercontent.com/u/25345045?s=460&u=ce388cd9c959128460483c392d51ffa097cd917d&v=4"/>

        </div>


        <div className="profile-container">
          <div className="content">
            <ProfileHeader
              displayName={displayName}
              userName={userName}
              link={link}
              avatar={avatar}
              description={description}
              country={country}
            />
            <div className="card">
                <CardHeader/>
                <LinkList links={links}/>
                <CardFooter/>
            </div>
          </div>
        </div>  
      </div>
      
    )
  }
}

export default ProfilePage;
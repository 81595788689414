import React from 'react';

import LinkItem from '../link-item/link-item.component';

import './link-list.styles.scss';

const LinkList = ({links}) => (
  <div className="links-list">
  {
    links.map(({title,user,icon,url,type})=>(
      <LinkItem 
        title={title}
        user={user}
        icon={icon}
        url={url}
        type={type}
      />
    ))
  }
  </div>
)

export default LinkList;